<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :page-info="pageInfo"
      @handleNewClick="handleSubmitClick"
      @resetBtnClick="handleReset"
    >
      <template #customForm>
        <el-col :span="12">
          <el-form-item
            required
            :label="$t('user.resource-menu')"
            class="form-item"
          >
            <resource-tree
              ref="resourceTreeRef"
              class="resource-menu"
              :style="{ height: '300px', width: '100%' }"
              :resources="allTreeResource"
              @handle-check-change="handleCheckChange"
              @handle-node-click="handleNodeClick"
              @handle-check="handleCheckEvent"
            ></resource-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('user.resource-btn')" class="form-item">
            <template #label>
              <span>{{ $t('user.resource-btn') }}</span>
              <span
                v-if="currentMenuTitle"
                :style="{
                  color: $store.getters.cssVar.menuBg,
                  'font-weight': 'bold',
                  'margin-left': '10px'
                }"
                >{{ currentMenuTitle }}</span
              >
            </template>
            <btn-table
              ref="resourceBtn"
              :listData="btnListData"
              @handle-selected-btns="handleSelectedBtns"
            ></btn-table>
          </el-form-item>
        </el-col>
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/resource.modal.js'
import { useStore } from 'vuex'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import _ from 'lodash' // 导入loadsh插件
import { useRouter } from 'vue-router'
import resourceTree from '@/components/resourceMenu/resource-tree.vue'
import btnTable from '@/components/resourceMenu/btn-table.vue'
import { ElMessage } from 'element-plus'

import { getItem } from '@/utils/storage'
import { RESOURCEMENUBTNLIST } from '@/utils/constant/index'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-resource-group-new')
const store = useStore()
const router = useRouter()
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const resourceBtn = ref('')
const allTreeResource = ref([])
const selectedResources = ref([])
const btnListData = ref([])
const btnList = ref([])
const pageInfo = ref({})

const resourceTreeRef = ref(null)
const resourceTreeParentNodeID = ref(0)
const currentMenuTitle = ref('')
const selectedBtns = ref([])

const resuorceMenuBtnList = store.getters.resuorceMenuBtnList || []

// 获取资源树菜单
const initMenuList = async () => {
  const resuorceMenu = _.cloneDeep(resuorceMenuBtnList)
  allTreeResource.value = [
    {
      id: '0',
      name: 'Resources',
      children: resuorceMenu,
      level: 0
    }
  ]
  const result = handleEntireTree(allTreeResource.value)
  allTreeResource.value = result
}

const handleReset = () => {
  selectedBtns.value = []
  resourceTreeRef.value.handleResetResources()
  resourceBtn.value.clearTableCheckedStatus()
  // 将resourceTreeRef搜索的值清空
  resourceTreeRef.value.filterText = ''
}
// 将后台接口数据转成渲染树所需格式
const handleEntireTree = (resources) => {
  return resources.map((item) => {
    if (item.children && item.children.length > 0 && item.level < 2) {
      item.children = handleEntireTree(item.children)
    } else {
      item.children = []
    }
    return {
      id: item.id,
      label: t(item.name),
      children: item.children,
      level: item.level
    }
  })
}

// 资源数菜单选中事件处理
const handleCheckChange = (selectedResource) => {
  selectedResources.value = selectedResource
}
// 处理菜单节点的点击
const handleNodeClick = (data) => {
  const { id } = data
  // 判断是否父级id
  const isParentNodes = resourceTreeParentNodeID.value === id

  // 当点击第一层父级时，则不执行函数
  if (!isParentNodes) clickMenu(data)
}

// 收集依赖数据
// const depsData = ref([])
const handleCheckEvent = (item, treeData) => {
  const isChecked = treeData.checkedNodes.includes(item)
  getCtxBtnResource(item, isChecked)
}

// 获取相关的按钮权限资源
const getCtxBtnResource = (resource, status) => {
  const { level, id, children } = resource
  // 判断level的层级
  if (level === 1 || level === 0) {
    if (children && children.length > 0) {
      children.forEach((item) => getCtxBtnResource(item, status))
    }
    // 查找id下面的另一层级
  } else if (level === 2) {
    getBtnResourceByMenuId(id, status)
  }
}

// 根据MenuId遍历添加btnResourceId
const getBtnResourceByMenuId = (menuId, status) => {
  const btnItems = getBtnList(menuId) || []
  const btnIds = btnItems.map((item) => item.id)

  if (status) {
    // 去重
    selectedBtns.value = [...new Set(selectedBtns.value.concat(btnIds))]
  } else {
    selectedBtns.value = selectedBtns.value.filter(
      (item) => !btnIds.includes(item)
    )
  }
}

// 根据当前id值获取按钮列表
const getBtnList = (id) => {
  const resuorceMenu = getItem(RESOURCEMENUBTNLIST) || []
  resuorceMenu.forEach((item) => {
    if (item.children && item.children.length) {
      item.children.forEach((child) => {
        if (child.id === id) {
          if (child.btnList && child.btnList.length > 0) {
            btnList.value = child.btnList.map((item) => {
              item.isChecked = selectedBtns.value.includes(item.id)

              return item
            })
          } else {
            btnList.value = []
          }
        }
      })
    } else {
      btnList.value = []
    }
  })
  return btnList.value
}

// 单独对按钮列表的操作 按钮级别
const handleSelectedBtns = (value, status) => {
  if (typeof value === 'string') {
    selectedBtns.value = status
      ? selectedBtns.value.push(value)
      : selectedBtns.value.filter((item) => item !== value)
  } else {
    selectedBtns.value = status
      ? selectedBtns.value.concat(value)
      : selectedBtns.value.filter((item) => !value.includes(item))
  }
  // 去重
  selectedBtns.value = [...new Set(Array.from(selectedBtns.value))]
}

// 根据菜单Id初始化当前菜单按钮列表
const initCurrentMenuBtnList = async (id) => {
  // 获取当前点击菜单的button 数组
  const btnArr = getBtnList(id)
  const btnListFormat = []
  for (const i in btnArr) {
    btnListFormat.push({
      id: btnArr[i].id,
      isChecked: btnArr[i].isChecked,
      disable: !isCheckedMenu(id), // 当点击的菜单没有被勾选时,那按钮列表应为禁用状态
      btn: btnArr[i]
    })
  }
  // 展示按钮区
  btnListData.value = btnListFormat
}

// 点击左侧菜单
const clickMenu = (data) => {
  initCurrentMenuBtnList(data.id)
  currentMenuTitle.value = t('user.current-resource-menu') + ': ' + data.label
}

// 判断点击的菜单是否被勾选
const isCheckedMenu = (id) => {
  return selectedResources.value.includes(id)
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  // 对于半选状态的父级也要传给后端
  const halfCheckedNodes = resourceTreeRef.value
    .getHalfCheckedNodes()
    .map((item) => item.id)
  form.resources = selectedResources.value.concat(
    halfCheckedNodes,
    selectedBtns.value
  )
  form.resources = form.resources.map((item) => {
    return {
      id: item
    }
  })
  if (!form.resources.length) {
    return ElMessage.error(t('popup.check-resource-group'))
  }
  handleMessageCommit('user/handleAddResourceAction', form).then(() => {
    router.push('/user/resource/group')
  })
}

initMenuList()
</script>

<style lang="scss" scoped>
.base-form {
  .form-item {
    padding: 5px 20px;
    .resource-menu {
      border: 1px solid #dcdfe6;
      overflow-y: scroll;
    }
  }
}
</style>
