export const baseFormConfig = {
  labelWidth: '180px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.resource-group-name',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'user.resource-group-desc',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 166
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    description: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
