<template>
  <div class="resource-tree">
    <el-input
      class="resource-tree__input"
      v-model="filterText"
      :placeholder="$t('user.enter-menu-keyword')"
    />
    <el-tree
      @check-change="handleCheckChange"
      default-expand-all
      ref="resourceTreeRef"
      :data="resources"
      show-checkbox
      node-key="id"
      @node-click="getCurrentNodeId"
      :props="defaultProps"
      :filter-node-method="filterNode"
      @check="handleCheck"
    >
    </el-tree>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  nextTick
} from 'vue'

const resourceTree = ref([])
const filterText = ref('')

const props = defineProps({
  resources: {
    type: Array,
    required: true
  },
  defaultProps: {
    type: Object,
    default: () => {
      return {
        children: 'children',
        label: 'label'
      }
    }
  }
})
const emit = defineEmits(['handle-check-change', 'handle-node-click'])

watch(filterText, (val) => {
  resourceTreeRef.value.filter(val)
})

const filterNode = (value, data) => {
  if (!value) return true
  return data.label.includes(value)
}

watch(
  () => props.resources,
  (newVal) => {
    if (newVal) {
      resourceTree.value = newVal
    }
  }
)
// 获取被选中的资源ID
const resourceTreeRef = ref(null)

// item 表示勾选的哪一项，status表示勾选还是取消勾选
const handleCheckChange = (item, status) => {
  const treeData = resourceTreeRef.value
    .getCheckedNodes(false, false)
    .map((item) => item.id)
  emit('handle-check-change', treeData, item, status)
}

const handleCheck = (item, treeData) => {
  emit('handle-check', item, treeData)
}

const getCurrentNodeId = (data) => {
  emit('handle-node-click', data)
}

// 处理清空所选的resources
const handleResetResources = () => {
  resourceTreeRef.value.setCheckedNodes([])
}
// 处理设置资源组
const handleSetResource = (resource) => {
  resourceTreeRef.value.setCheckedNodes(resource)
}
// 获取半选状态的资源
const getHalfCheckedNodes = () => {
  return resourceTreeRef.value.getHalfCheckedNodes()
}
// 处理相关的初始化操作
const handleInitialEditData = (data) => {
  // 这一步的目的是为了编辑状态重置时 先清空掉所有菜单勾选状态，再为初始已勾选菜单赋值
  handleResetResources()
  nextTick(() => {
    data.forEach((item) => {
      // 第一个参数key/data 第二个参数为checked 第三个为deep
      resourceTreeRef.value.setChecked(item, true, false)
    })
  })
}

defineExpose({
  filterText,
  handleResetResources,
  handleSetResource,
  handleInitialEditData,
  getHalfCheckedNodes
})
</script>
<style lang="scss" scoped>
.resource-tree {
  padding: 8px;
  &__input {
    margin-bottom: 6px;
  }
}
</style>
