<template>
  <el-table
    class="btn-table"
    ref="btnTable"
    :data="listData"
    border
    stripe
    height="300"
    @select="handleSelect"
    @select-all="selectAll"
    :row-style="{ height: '48px' }"
    :cell-style="{ padding: '0' }"
  >
    <el-table-column
      type="selection"
      class-name="isCheck"
      :selectable="checkboxIsDisable"
      align="center"
      width="60"
    ></el-table-column>
    <template v-for="item in btnTableList" :key="item.props">
      <el-table-column :label="item.label" :width="item.width">
        <template v-if="item.label === 'Button'" #default="scope">
          <div class="table-data">
            {{ btnName(scope.row.btn[item.props]) }}
          </div>
        </template>
        <template v-else #default="scope">
          <div class="table-data">
            {{ scope.row.btn[item.props] }}
          </div>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script setup>
import {
  defineProps,
  ref,
  watch,
  computed,
  nextTick,
  defineExpose,
  defineEmits
} from 'vue'
import { btnTableList } from '@/utils/constant/const.js'

const props = defineProps({
  listData: {
    default: () => [],
    type: Array,
    require: true
  }
})
const btnTable = ref('')
const selectedBtnList = ref([])
const initCheckBoxStatus = () => {
  nextTick(() => {
    props.listData.forEach((row) => {
      if (row.isChecked) {
        selectedBtnList.value.push(row)
        btnTable.value.toggleRowSelection(row, true)
      }
    })
  })
}

// 清空table 勾选状态
const clearTableCheckedStatus = () => {
  nextTick(() => {
    props.listData.forEach((row) => {
      btnTable.value.toggleRowSelection(row, false)
    })
  })
}

const btnName = computed(() => {
  return (data) => {
    const arr = data && data.split('.')
    const len = arr && arr.length
    return len ? arr[len - 1] : ''
  }
})

watch(
  () => props.listData,
  () => {
    initCheckBoxStatus()
  },
  { immediate: true, deep: true }
)
const checkboxIsDisable = (row) => {
  return !row.disable
}
const emit = defineEmits(['handle-selected-btns'])

// list是所有勾选数据，item是当前点击的数据
const handleSelect = (list, item) => {
  if (list.includes(item)) {
    // 勾选时做的事
    if (!selectedBtnList.value.includes(item)) {
      selectedBtnList.value.push(item)
    }
    emit('handle-selected-btns', item.id, true)
  } else {
    selectedBtnList.value.map((i, index) => {
      if (i.id === item.id) {
        selectedBtnList.value.splice(index, 1)
      }
    })
    emit('handle-selected-btns', item.id, false)
    // 取消勾选时做的事，arguments[1]是当前取消勾选的项
  }
}
const selectAll = (tableData) => {
  const dom = document.querySelector('.isCheck>div>label')
  // 全选
  const selectedBtnIds = selectedBtnList.value.map((item) => item.id)

  if (!dom.className.includes('is-checked')) {
    tableData.map((item) => {
      if (!selectedBtnIds.includes(item.id)) {
        selectedBtnList.value.push(item)
      }
    })
    const btnIds = tableData.map((item) => item.id)
    emit('handle-selected-btns', btnIds, true)
  } else {
    // 取消全选
    const tableDataIDs = props.listData.map((item) => item.id)

    // 在勾选按钮列表中过滤掉当前菜单所含有的所有按钮
    selectedBtnList.value = selectedBtnList.value.filter((item) => {
      return !tableDataIDs.includes(item.id)
    })
    const btnIds = props.listData.map((item) => item.id)
    emit('handle-selected-btns', btnIds, false)
  }
}
defineExpose({
  selectedBtnList,
  clearTableCheckedStatus,
  initCheckBoxStatus
})
</script>

<style lang="scss" scoped>
.btn-table {
  .table-data {
    display: flex;
    align-items: center;
  }
}
</style>
